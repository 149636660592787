<template>
	<b-navbar type="dark" variant="dark" sticky>
		<b-navbar-brand href="#" variant="light">
			Word Freq
		</b-navbar-brand>
		<b-navbar-nav class="ml-auto align-items-center">
			<!-- <b-button class="mr-2" @click="openCite" variant="success">Cite</b-button> -->
			<!--<b-button class="mr-2" @click="openHelp" variant="info">Help</b-button>-->
			<div class="mr-2 text-white">{{ saveStatus }}</div>
				<b-button v-if="saveStatus !== 'Saved'" class="mr-2 btn btn-success" @click="save">Save</b-button>
			<b-button class="btn btn-danger" v-if="projectOpen" @click="$store.dispatch('resetStore')">
				Clear
			</b-button>
		</b-navbar-nav>
	</b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import store, { teraSyncPlugin } from '../store'
import { BNavbar, BNavbarBrand, BNavbarNav, BButton } from 'bootstrap-vue';

export default {
	name: 'TheNavbar',
	props: {
		projectOpen: Boolean
	},
	data() {
		return {
			isEditTitle: false,
			tempTitle: ""
		}
	},
	components: {
		BNavbar,
		BNavbarBrand,
		BNavbarNav,
		BButton,
	},
	computed: {
		...mapGetters('metadata', {
			title: 'getTitle'
		}),
		...mapGetters('__tera_file_sync', {
      saveStatus: 'getSaveStatus'
    })
	},
	methods: {
		/*openCite() {
			// TODO: Open citation page
		},
		openHelp() {
			// Open help page
			window.open('https://sr-accelerator.com/#/help/wordfreq','_blank')
		},*/
		save() {
			teraSyncPlugin(store).saveState();
		},
		saveTitle() {
			this.$store.commit('metadata/setTitle', this.tempTitle);
			this.isEditTitle = false;
		},
		cancelEdit() {
			this.isEditTitle = false;
			this.tempTitle = this.title;
		}
	}
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import metadata from './modules/metadata'
import words from './modules/words'
import { createSyncPlugin } from '@iebh/vuex-tera-json';

Vue.use(Vuex)

// Create TERA sync plugin with configuration
export const teraSyncPlugin = createSyncPlugin('wordFreq', false, {
	debounceMs: 100
})

export default new Vuex.Store({
	modules: {
		metadata,
		words,
	},
	plugins: [teraSyncPlugin],
	actions: {
		resetStore({ commit }) {
			commit('metadata/reset');
			commit('words/reset');
		}
	}
})